<template>
    <n-form ref="formRef" inline :model="formValue" :rules="rules" size="large" v-if="is_loaded">
        <n-space vertical>
            <n-space>
                <n-form-item label="Дата" path="date">
                    <n-date-picker v-model:value="formValue.date" type="date" format="dd MMMM yyyy" :first-day-of-week="0"
                        placeholder="Укажите дату отчета" style="width: 472px; max-width: 100%" />
                </n-form-item>
            </n-space>
            <n-space justify="space-between" style="gap: 0px 20px;">
                <n-form-item label="Начало рабочего дня" path="start_time">
                    <n-time-picker v-model:value="formValue.start_time" default-formatted-value="10:00" format="HH:mm"
                        placeholder="Укажите время" style="width: 218px; max-width: 100%" />
                </n-form-item>
                <n-form-item label="Конец рабочего дня" path="end_time">
                    <n-time-picker v-model:value="formValue.end_time" default-formatted-value="18:00" format="HH:mm"
                        placeholder="Укажите время" style="width: 218px; max-width: 100%" />
                </n-form-item>
            </n-space>
            <n-form-item label="Комментарий" >
                <n-input v-model:value="formValue.comment" type="textarea" />
            </n-form-item>
            <n-space>
                <n-form-item label="Задачи">
                    <n-dynamic-input v-model:value="formValue.tasks" :min="1" :on-create="onCreate" #="{ index, value }">
                        <div style="display: flex; flex-direction: column; justify-content: space-between; width: 100%;">
                            <n-divider title-placement="left" style="font-size: 14px; margin-top: 10px;">
                                Задача №{{ index + 1 }}
                            </n-divider>
                            <n-form-item ignore-path-change :path="`tasks[${index}].id`" :show-label="false"
                                :rule="dynamicInputRule">
                                <n-select v-model:value="formValue.tasks[index].id" :options="options"
                                    style="width: 400px;" placeholder="Выберите задачу" />
                            </n-form-item>
                            <n-form-item ignore-path-change :path="`tasks[${index}].time`" :show-label="false"
                                :rule="dynamicInputRule">
                                <n-time-picker v-model:value="formValue.tasks[index].time" default-formatted-value="00:00"
                                    format="HH:mm" style="width: 400px;" placeholder="Укажите время" />
                            </n-form-item>
                        </div>
                    </n-dynamic-input>
                </n-form-item>
            </n-space>
            <n-space>
                <n-form-item label="Проверен руководителем" v-if="role == 'director' || role == 'boss'">
                    <n-switch size="large" v-model:value="formValue.verified_direct_supervisor">
                        <template #checked>
                            Проверен
                        </template>
                        <template #unchecked>
                            Не проверен
                        </template>
                    </n-switch>
                </n-form-item>
                <n-form-item label="Утвержден" v-if="role == 'boss'">
                    <n-switch size="large" v-model:value="formValue.verified_supervisor">
                        <template #checked>
                            Утвержден
                        </template>
                        <template #unchecked>
                            Не утвержден
                        </template>
                    </n-switch>
                </n-form-item>
            </n-space>
            <n-space justify="space-between">
                <n-button type="primary" :loading="loading" @click="update">
                    Обновить
                </n-button>
                <n-button secondary type="error" :loading="loading_delete" @click="deleteReport">
                    Удалить
                </n-button>
            </n-space>
        </n-space>
    </n-form>
    <n-space justify="center" align="center" v-else style="height: 100%;">
        <n-spin size="large" />
    </n-space>
</template>
<script>
import { getFormatedDate, getFormatedTime } from '@/assets/js/helper.js'
import { defineComponent, ref, toRefs, onMounted } from "vue";
import store from '@/store';
import { useNotification } from "naive-ui";
import tasksApi from '@/assets/js/api/tasks.js'
import reportsApi from '@/assets/js/api/reports.js'


export default defineComponent({
    props: {
        report_id: null,
    },
    emits: ['update'],
    setup(props, context) {
        const formRef = ref(null);
        const loadingRef = ref(false);
        const loading_deleteRef = ref(false);
        const notification = useNotification();
        const options = ref(null);
        const is_loaded = ref(false);

        const role = store.getters.user?.role_api?.value;

        let formValue = ref({
            date: null,
            start_time: null,
            end_time: null,
            tasks: [{
                id: null,
                time: null
            }],
            verified_direct_supervisor: false,
            verified_supervisor: false,
        });

        const { report_id } = toRefs(props);

        const getReport = async () => {
            is_loaded.value = false;

            const result_options = await tasksApi.getList();

            if (result_options?.success) {
                options.value = result_options.tasks;
            }

            const result = await reportsApi.getById(report_id.value);

            if (result?.success) {
                formValue.value = {
                    date: Date.parse(getFormatedDate(result.report.date)),
                    start_time: Date.parse(getFormatedTime(result.report.start_time)),
                    end_time: Date.parse(getFormatedTime(result.report.end_time)),
                    comment: result.report.comment.replaceAll('<br />', ''),
                    tasks: result.report.tasks.map(task => {
                        return {
                            id: task.id,
                            time: Date.parse(task.time)
                        }
                    }),
                    verified_direct_supervisor: result.report.verified_direct_supervisor ?? false,
                    verified_supervisor: result.report.verified_supervisor ?? false,
                };
            }

            is_loaded.value = true;
        }

        onMounted(getReport);

        return {
            formRef,
            formValue,
            options,
            is_loaded,
            role,
            onCreate() {
                return {
                    id: null,
                    time: null
                };
            },
            rules: {
                date: {
                    required: true,
                    message: "Обязательное поле",
                },
                start_time: {
                    required: true,
                    message: "Обязательное поле",
                },
                end_time: {
                    required: true,
                    validator(rule, value) {
                        if (!value) {
                            return new Error('Обязательное поле')
                        }
                        else if (formValue.value.start_time >= value) {
                            return new Error('Неверная дата')
                        }
                        return true;
                    }
                },
            },
            dynamicInputRule: {
                required: true,
                message: "Обязательное поле",
            },
            loading: loadingRef,
            loading_delete: loading_deleteRef,
            async update(e) {
                e.preventDefault();

                loadingRef.value = true;

                await formRef.value?.validate(async (errors) => {
                    if (!errors) {

                        const result = await reportsApi.update(formValue.value, report_id.value);

                        if (result.success) {
                            notification.success({
                                content: "Отчет #" + report_id.value + " обновлен.",
                                duration: 10000,
                            });
                            context.emit('update');
                        } else {
                            notification.error({
                                content: result.message,
                                duration: 10000,
                            });
                        }
                    } else {
                        notification.error({
                            content: "Неверные данные формы",
                            duration: 10000,
                        });
                    }

                    loadingRef.value = false;
                });
            },
            async deleteReport(e) {
                e.preventDefault();

                loading_deleteRef.value = true;

                const result = await reportsApi.delete(report_id.value);

                if (result.success) {
                    notification.success({
                        content: "Отчет #" + report_id.value + " удален.",
                        duration: 10000,
                    });
                    context.emit('update');
                } else {
                    notification.error({
                        content: result.message,
                        duration: 10000,
                    });
                }

                loading_deleteRef.value = false;
            },
        }
    }
});
</script>