import Api from "@/assets/js/api/index.js";

class TasksApi extends Api {
    async getList() {
        try {
            const result = await this.send('methods/tasks/list');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async get(page = 1, status = 'Не выполнено') {
        try {
            const result = await this.send('methods/tasks/?' + new URLSearchParams({page, status}).toString());

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async create(fields, user_id = false) {
        try {
            if (!fields) {
                return false;
            }

            const data = {
                name: fields.name,
                fields: {
                    date:           new Date(fields.date).toGMTString(),
                    estimated_time: new Date(fields.estimated_time).toGMTString(),
                    status:         fields.status,
                    difficulty:     fields.difficulty ?? 2,
                    bitrix_id:      fields.bitrix_id,
                }
            }

            if (user_id) {
                data.for_user = user_id;
            }

            const result = await this.send('methods/task/create', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async update(fields, id, user_id = false) {
        try {
            if (!fields || !id) {
                return false;
            }

            const data = {
                id,
                name: fields.name,
                fields: {
                    date:           new Date(fields.date).toGMTString(),
                    estimated_time: new Date(fields.estimated_time).toGMTString(),
                    status:         fields.status,
                    difficulty:     fields.difficulty ?? 2,
                    bitrix_id:      fields.bitrix_id,
                },
            }

            if (user_id) {
                data.for_user = user_id;
            }

            const result = await this.send('methods/task/update', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async delete(id) {
        try {
            if (!id) {
                return false;
            }

            const data = {
                id
            }

            const result = await this.send('methods/task/delete', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new TasksApi();