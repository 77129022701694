<template>
    <div class="home page">
        <header>
            <div class="container">
                <n-space justify="space-between" align="center">
                    <n-space align="center" class="w-100">
                        <n-h1 style="margin-bottom: 0;">{{ $store.state?.user?.data?.display_name }}</n-h1>
                        <n-tag size="small" style="opacity: 0.6;">{{ $store.state?.user?.role_api?.label }}</n-tag>
                    </n-space>

                    <n-space align="center" vertical>
                        <div class="date">
                            {{ date }}
                        </div>
                        <div class="time">
                            {{ time }}
                        </div>
                    </n-space>
                    
                    <n-space>
                        <n-button @click="logout">
                            Выход
                        </n-button>
                    </n-space>
                </n-space>
            </div>
        </header>
        <div class="container">
            <component :is="view"></component>
        </div>
    </div>
</template>
    
<script>
import moment from 'moment'
import userApi from '@/assets/js/api/user.js'

import Boss from '@/views/Boss.vue'
import Director from '@/views/Director.vue'
import SeniorEmployee from '@/views/SeniorEmployee.vue'
import Employee from '@/views/Employee.vue'

export default {
    name: 'HomeView',
    components: {
        Boss,
        Director,
        SeniorEmployee,
        Employee
    },
    data() {
        return {
            view: '',
            date: '',
            time: '',
        }
    },
    methods: {
        logout() {
            userApi.logout();
        }
    },
    async created() {
        if (!this.$store.user?.ID) {
            const result = await userApi.me();

            if (result.susses) {
                this.$store.commit('user', result.user);

                switch (result.user?.role_api?.value) {
                    case 'boss':
                        this.view = 'Boss';
                        break;
                    case 'director':
                        this.view = 'Director';
                        break;
                    case 'senior_employee':
                        this.view = 'SeniorEmployee';
                        break;
                    default:
                        this.view = 'Employee';
                        break;
                }

                moment.locale('ru');

                setInterval(() => {
                    this.time = moment().format('LTS'); 
                    this.date = moment().format('dd, DD MMMM');
                }, 1000);               
            } else {
                this.$router.push({ path: 'login' });
            }
        }
    }
}
</script>

<style>
header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 10px 0;
    background-color: rgba(16, 16, 20, 0.5);
    backdrop-filter: blur(5px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.time {
    font-size: 24px;
    line-height: 24px;
    font-family: monospace;
}
</style>