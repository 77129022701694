<template>
    <div class="today-report">
        <div class="reports-header">
            <n-h2>Текущий отчет ( <n-time format="dd MMMM" /> )</n-h2>
        </div>
        <template v-if="is_loaded">
            <ReportCard v-if="report" :data="report" @edit="$emit('edit-report', report.id)"/>
            <div class="empty-report" v-else>
                <n-empty description="Создайте отчет за сегодня">
                    <template #extra>
                        <n-button @click="$emit('new-report')">
                            Создать новый отчет
                        </n-button>
                    </template>
                </n-empty>
            </div>
        </template>
        <n-space justify="center" align="center" v-else style="height: 100%;">
            <n-spin size="large" />
        </n-space>
    </div>
</template>
    
<script>
import reportsApi from '@/assets/js/api/reports.js'
import ReportCard from '@/components/reports/ReportCard.vue'

export default {
    components: {
        ReportCard
    },
    data() {
        return {
            report: null,
            is_loaded: false,
        }
    }, 
    methods: {
        async getReport() {
            this.is_loaded = false;

            const result = await reportsApi.getToday();

            if (result.success) {
                this.report = result.report;
            }

            this.is_loaded = true;
        },
        update() {
            this.getReport();
        },
    },
    emits: ['new-report', 'edit-report'],
    created() {
        this.getReport();
    }
}
</script>

<style>
.empty-report {
    height: 200px;
    border: 2px #2d2d30 dashed;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>