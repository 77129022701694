<template>
    <n-space vertical>
        <n-h2>Отчет за месяц</n-h2>
        <n-collapse-transition :show="data.length == 0">
            <n-button @click="getReport" :loading="loading">Получить отчет</n-button>
        </n-collapse-transition>
        <n-collapse-transition :show="data.length > 0">
            <n-data-table :columns="columns" :data="data" />
        </n-collapse-transition>
    </n-space>
</template>

<script setup>
import reports from '@/assets/js/api/reports';
import { ref } from 'vue';
import { numberWithSpaces } from '@/assets/js/helper'

const data = ref([]);
const loading = ref(false);

const columns = [
    {
        title: 'ID',
        key: 'user_id'
    },
    {
        title: 'Имя',
        key: 'name'
    },
    {
        title: 'Кол-во отчетов',
        key: 'reports'
    },
    {
        title: 'Кол-во задач',
        key: 'tasks_count'
    },
    {
        title: 'Задач завершено',
        key: 'tasks_completed'
    },
    {
        title: 'KPI',
        key: 'kpi_value'
    },
    {
        title: 'Оцененных часов',
        key: 'dedicated_hours'
    },
    {
        title: 'Потраченных часов',
        key: 'used_hours'
    },
    {
        title: 'Начислено',
        key: 'payment',
        render(row) {
            if (row.rate?.type == 'dynamic') {
                const payment = (Number(row.dedicated_hours * row.rate.rate) + Number(row.rate.allowance)).toFixed(2);
                return numberWithSpaces(payment);
            } else if (row.rate?.type == 'static') {
                const payment = (Number(row.rate.salary) + Number(row.rate.allowance)).toFixed(2);
                return numberWithSpaces(Number(row.rate.salary) + Number(row.rate.allowance));
            } else {
                return '-';
            }
        }
    }
];

async function getReport() {
    loading.value = true;
    data.value = await reports.mouthlyReport();
    loading.value = false;
}
</script>
