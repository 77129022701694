<template>
    <div class="widget-list">
        <RouterLink to="/tasks" style="width: max-content;">
            Перейти к задачам
        </RouterLink>
        <MouthlyReport />
        <TodayReports @info-report="info_report" ref="todayReports" />
        <n-tabs type="line" animated>
            <n-tab-pane name="unfulfilled" tab="Не выполненные">
                <TasksList @new-task="open('new_task')" @edit-task="edit_task" ref="unfulfilledTasksList" status="Не выполнено"/>
            </n-tab-pane>
            <n-tab-pane name="completed" tab="Выполненные">
                <TasksList @new-task="open('new_task')" @edit-task="edit_task" ref="completedTasksList" status="Выполнено"/>
            </n-tab-pane>
        </n-tabs>
        <ReportsList  @new-report="open('new_report')" @info-report="info_report" ref="reportsList"/>
    </div>
    <n-drawer v-model:show="drawers.new_report" :width="520">
        <n-drawer-content title="Создать новый отчет" closable>
            <NewReportForm @create="update"/>
        </n-drawer-content>
    </n-drawer>
    <n-drawer v-model:show="drawers.new_task" :width="520">
        <n-drawer-content title="Создать новую задачу" closable>
            <NewTaskForm @create="update"/>
        </n-drawer-content>
    </n-drawer>
    <n-drawer v-model:show="drawers.edit_task" :width="520">
        <n-drawer-content title="Редактировать задачу" closable>
            <EditTaskForm @update="update" :data="edit_task_data"/>
        </n-drawer-content>
    </n-drawer>
    <n-drawer v-model:show="drawers.edit_report" :width="520">
        <n-drawer-content title="Редактировать отчет" closable>
            <UpdateReportForm @update="update" :report_id="edit_report_id"/>
        </n-drawer-content>
    </n-drawer>
    <n-modal
        v-model:show="drawers.info_report"
        preset="card"
        style="width: auto;"
    >
        <ReportCard :id="info_report_id" @edit="edit_report(info_report_id)"/>
    </n-modal>

</template>

<script>
import userApi from '@/assets/js/api/user.js'
import TasksList from '@/components/tasks/TasksList.vue'
import ReportsList from '@/components/reports/ReportsList.vue'
import NewReportForm from '@/components/reports/NewReportForm.vue'
import NewTaskForm from '@/components/tasks/NewTaskForm.vue'
import EditTaskForm from '@/components/tasks/EditTaskForm.vue'
import UpdateReportForm from '@/components/reports/UpdateReportForm.vue'
import ReportCard from '@/components/reports/ReportCard.vue'
import TodayReports from '@/components/reports/TodayReports.vue'
import MouthlyReport from '@/components/reports/MouthlyReport.vue'

export default {
    name: 'HomeView',
    components: {
        TasksList,
        ReportsList,
        NewReportForm,
        NewTaskForm,
        EditTaskForm,
        UpdateReportForm,
        ReportCard,
        TodayReports,
        MouthlyReport
    },
    data() {
        return {
            drawers: {
                new_report:  false,
                new_task:    false,
                edit_report: false,
                edit_task:   false,
                info_report: false,
            },
            info_report_id: null,
            edit_report_id: null,
            edit_task_data: null,
        }
    },
    methods: {
        open(type) {
            for (const key in this.drawers) {
                if (type == key ) {
                    this.drawers[type] = true;
                } else {
                    this.drawers[key] = false;
                }
            }
        },
        update() {
            this.drawers.new_task = false;
            this.drawers.new_report = false;
            this.drawers.edit_report = false;
            this.drawers.edit_task = false;
            this.$refs.reportsList.update();
            this.$refs.unfulfilledTasksList.update();
            this.$refs.completedTasksList.update();
            this.$refs.todayReports.update();
        },
        edit_report(report_id) {
            this.open('edit_report');
            this.edit_report_id = report_id;
        },
        info_report(report_id) {
            this.open('info_report');
            this.info_report_id = report_id;
        },
        edit_task(task) {
            this.open('edit_task');
            this.edit_task_data = task;
        },
        logout() {
            userApi.logout();
        }
    },
}
</script>