<template>
    <div class="tasks-column">
        <div class="tasks-column-title" @click="log">
            {{ title }} ({{ tasks.length }})
        </div>
        <n-scrollbar style="height: calc(100vh - 270px); border-radius: 4px;">
            <div class="tasks-column-cards">
                <card v-for="task in sorted_tasks" :data="task" :key="task?.id"/>
            </div>
        </n-scrollbar>
    </div>
</template>

<script setup>
import Card from './Card.vue';
import { defineProps, computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: '',
    },
    tasks: {
        type: Array,
        default: () => [] 
    }
});

const title = computed(() => {
    let result;

    switch (props.type) {
        case 'estimation':
            result = 'Оценка'
            break;
        case 'development':
            result = 'Разработка'
            break;
        case 'testing':
            result = 'Тестирование'
            break;
        case 'verification':
            result = 'Проверка'
            break;
        default:
            result = '-'
            break;
    }

    return result;
});

const sorted_tasks = computed(() => {
    let tasks = props.tasks;

    tasks.sort((task_a, task_b) => {
        if (!task_a.due) {
            if (!task_b.due) {
                return 0;
            } else {
                return 1;
            }
        }

        if (!task_b.due) {
            return -1;
        }

        const date_a = new Date(task_a.due);
        const date_b = new Date(task_b.due);
    
        if (date_a.getTime() == date_b.getTime()) {
            return 0;
        } else if (date_a.getTime() > date_b.getTime()) {
            return 1;
        } else {
            return -1;
        }
    });

    return tasks;
});
</script>

<style>
.tasks-column {
    width: 340px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tasks-column-title {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 10px;
}

.tasks-column-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>