<template>
    <div class="tasks">
        <div class="tasks-header">
            <n-h2 v-if="status == 'Не выполнено'">Текущие задачи</n-h2>
            <n-h2 v-else>Выполненные задачи</n-h2>
            <n-button @click="createNew">Создать новую задачу</n-button>
        </div>
        <template v-if="is_loaded">
            <n-data-table :columns="columns" :data="tasks" :row-props="rowProps"/>
            <n-pagination v-model:page="page" :page-count="page_count" style="margin-top:20px"/>
        </template>
        <n-space justify="center" align="center" v-else style="min-height: 680px; height: 100%;">
            <n-spin size="large" />
        </n-space>
    </div>
</template>
    
<script>
import tasksApi from '@/assets/js/api/tasks.js'
import { h } from 'vue'
import { NTag } from 'naive-ui'
import LinkVue from '@/components/base/Link.vue'

export default {
    name: 'Tasks List',
    props: {
        status: 'Не выполнено',
    },
    data() {
        return {
            page: 1,
            page_count: 0,
            tasks: [],
            is_loaded: false,
        }
    },
    methods: {
        async getTasks() {
            this.is_loaded = false;

            const result = await tasksApi.get(this.page, this.status);

            if (result.success) {
                this.tasks      = result.tasks;
                this.page_count = result.all_page;
            }

            this.is_loaded = true;
        },
        createNew() {
            this.$emit('new-task');
        },
        update() {
            if (this.page == 1) {
                this.getTasks();
            } else {
                this.page = 1;
            }
        },
    },
    emits: ['new-task', 'edit-task'],
    watch: {
        page(new_value) {
            this.getTasks()
        }
    },
    computed: {
        columns() {
            return [
                {
                    title: 'ID',
                    key: 'id',
                    className: 'no-wrap'
                },
                {
                    title: 'Битрикс 24',
                    key: 'bitrix_id',
                    align: 'center',
                    render(row) {
                        if ( row.bitrix_id) {
                            return h(
                                LinkVue,
                                {
                                    link:  'https://yourstartup.bitrix24.ru/company/personal/user/0/tasks/task/view/' + row.bitrix_id + '/',
                                    target: '_blank'
                                },
                                {
                                    default: () => 'Задача №' + row.bitrix_id
                                }
                            );
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    title: 'Название',
                    key: 'name'
                },
                {
                    title: 'Дата постановки',
                    key: 'date',
                    align: 'center'
                },
                {
                    title: 'Время (оценка)',
                    key: 'estimated_time',
                    align: 'center',
                    className: 'no-wrap'
                },
                {
                    title: 'Время (потраченное)',
                    key: 'history',
                    align: 'center',
                    className: 'no-wrap'
                },
                {
                    title: 'Исполнители',
                    key: 'executor',
                    align: 'center',
                    render(row) {
                        const tags = row.executor.map((tagKey) => {
                            return h(
                                NTag,
                                {
                                    style: 'width: 100%; justify-content: center;'
                                },
                                {
                                    default: () => tagKey
                                }
                            )
                        })
                        return tags
                    }
                },
                {
                    title: 'Сложность',
                    key: 'difficulty',
                    align: 'center',
                    render(row) {
                        let type  = '',
                            label = '';

                        switch (row.difficulty) {
                            case 'Легкая':
                                type  = 'success';
                                label = 'Легкая';
                                break;

                            case 'Сложная':
                                type = 'info';
                                label = 'Сложная';
                                break;

                            default:
                                type = 'default';
                                label = 'Обычная';
                                break;
                        }

                        return h(NTag,
                            {
                                type: type,
                                bordered: false,
                                style: 'min-width: 80px; justify-content: center;'
                            },
                            {
                                default: () => label
                            }
                        )
                    }
                },
                // {
                //     title: 'Статус',
                //     key: 'status',
                //     align: 'center',
                //     render(row) {
                //         let type = '';

                //         switch (row.status) {
                //             case 'Выполнено':
                //                 type = 'success';
                //                 break;

                //             case 'Не выполнено':
                //                 type = 'error';
                //                 break;

                //             default:
                //                 type = 'info';
                //                 break;
                //         }

                //         return h(NTag,
                //             {
                //                 type: type,
                //                 bordered: false
                //             },
                //             {
                //                 default: () => row.status
                //             }
                //         )
                //     }
                // },
                {
                    title: 'Оценка',
                    key: 'kpi',
                    align: 'center',
                    render(row) {
                        let type = '',
                            text = row.kpi;

                        switch (row.kpi) {
                            case 'Хорошо':
                                type = 'success';
                                break;
                        
                            case 'Нормально':
                                type = 'info';
                                break;

                            case 'Плохо':
                                type = 'error';
                                break;
                        }

                        return h(NTag,
                            {
                                type: type,
                                bordered: false,
                                style: 'width: 100%; justify-content: center;'
                            },
                            {
                                default: () => text
                            }
                        )
                    }
                },
            ]
        }
    },
    async created() {
        this.getTasks();
    },
    setup(props, context) {
        return {
            rowProps: (row) => {
                return {
                    style: 'cursor: pointer;',
                    onClick: () => {
                        context.emit('edit-task', row);
                    }
                }
            },
        }
    }
}
</script>

<style>
    .tasks-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .no-wrap {
        white-space: nowrap;
    }
</style>