<template>
    <n-form ref="formRef" inline :model="formValue" :rules="rules" size="large">
        <n-space vertical> 
            <n-space vertical>
                <n-form-item label="ID задачи на Битрикс 24" path="bitrix">
                    <n-input-number 
                        v-model:value="formValue.bitrix_id"
                        placeholder="Введите id задачи"
                        style="width: 472px; max-width: 100%" 
                    />
                </n-form-item>
                <n-form-item label="Название" path="name">
                    <n-input 
                        v-model:value="formValue.name"
                        placeholder="Введите название задачи"
                        style="width: 472px; max-width: 100%" 
                    />
                </n-form-item>
                <n-form-item label="Дата" path="date">
                    <n-date-picker 
                        v-model:value="formValue.date" 
                        type="date" format="dd MMMM yyyy" 
                        :first-day-of-week="0"
                        placeholder="Укажите дату постановки" 
                        style="width: 472px; max-width: 100%"
                    />
                </n-form-item>
                <n-form-item label="Время на выполнение" path="estimated_time">
                    <n-time-picker 
                        v-model:value="formValue.estimated_time" 
                        default-formatted-value="10:00" 
                        format="HH:mm" 
                        placeholder="Укажите время на выполнение" 
                        style="width: 472px; max-width: 100%" 
                    />
                </n-form-item>
                <n-form-item label="Статус" path="status">
                    <n-select 
                        v-model:value="formValue.status" 
                        :options="options" 
                        style="width: 472px; max-width: 100%" 
                        placeholder="Укажите статус"
                    />
                </n-form-item>
                <n-form-item label="Сложность" v-if="role != 'employee'">
                    <n-select 
                        v-model:value="formValue.difficulty" 
                        :options="difficulty_options" 
                        :default-value="defaultDifficulty"
                        style="width: 472px; max-width: 100%" 
                        placeholder="Укажите cложность"
                    />
                </n-form-item>
            </n-space>
            <n-space justify="space-between">
                <n-button type="primary" :loading="loading" @click="update">
                    Обновить
                </n-button>
                <n-button secondary type="error" :loading="loading_delete" @click="deleteTask">
                    Удалить
                </n-button>
            </n-space>
        </n-space>
    </n-form>
</template>
<script>
import { getFormatedDate, getFormatedTime } from '@/assets/js/helper.js'
import store from '@/store'
import { defineComponent, ref } from "vue";
import { useNotification } from "naive-ui";
import tasksApi from '@/assets/js/api/tasks.js' 

export default {
    name: 'Edit Task',
    props: {
        data: null,
    },
    emits: ['update'],
    setup(props, context) {
        const formRef = ref(null);
        const loadingRef = ref(false);
        const loading_deleteRef = ref(false);
        const notification = useNotification();
        const role = store.getters.user?.role_api?.value;
        const options = [
            {
                label: 'Выполнено',
                value: 'Выполнено'
            },
            {
                label: 'Не выполнено',
                value: 'Не выполнено'
            }
        ];
        const  difficulty_options = [
            {
                label: 'Легкая',
                value: 1,
                selected: props.data.difficulty == 1 
            },
            {
                label: 'Обычная',
                value: 2,
                selected: props.data.difficulty == 2
            },
            {
                label: 'Сложная',
                value: 3,
                selected: props.data.difficulty == 3
            }
        ];

        function defaultDifficulty() {
            let result = '';

            switch (props.data?.difficulty) {
                case '1':
                    result = 'Легкая';
                    break;
                case '3':
                    result = 'Сложная';
                    break;
                default: 
                    result = 'Обычная';
                    break;
            }

            return result;
        }

        let formValue = ref({
            bitrix_id: props.data.bitrix_id,
            name: props.data.name,
            date: Date.parse(getFormatedDate(props.data.date)),
            estimated_time: Date.parse(getFormatedTime(props.data.estimated_time)),
            status: props.data.status,
            difficulty: defaultDifficulty(),
        });

        return {
            formRef,
            formValue,
            role,
            options,
            difficulty_options,
            rules: {
                name: {
                    required: true,
                    message: "Обязательное поле",
                },
                date: {
                    required: true,
                    message: "Обязательное поле",
                },
                estimated_time: {
                    required: true,
                    message: "Обязательное поле",
                },
                status: {
                    required: true,
                    message: "Обязательное поле",
                },
            },
            loading: loadingRef,
            loading_delete: loading_deleteRef,
            async update(e) {
                e.preventDefault();

                loadingRef.value = true;

                await formRef.value?.validate(async (errors) => {
                    if (!errors) {
                        const result = await tasksApi.update(formValue.value, props.data.id);

                        if (result.success) {
                            notification.success({
                                content: "Задача #" + props.data.id + " обнавлена.",
                                duration: 10000,
                            });
                            context.emit('update');
                        } else {
                            notification.error({
                                content: result.message,
                                duration: 10000,
                            });
                        }
                    } else {
                        notification.error({
                            content: "Неверные данные формы",
                            duration: 10000,
                        });
                    }

                    loadingRef.value = false;
                });
            },
            async deleteTask(e) {
                e.preventDefault();

                loading_deleteRef.value = true;

                const result = await tasksApi.delete(props.data.id);

                if (result.success) {
                    notification.success({
                        content: "Задача #" + props.data.id + " удалена.",
                        duration: 10000,
                    });
                    context.emit('update');
                } else {
                    notification.error({
                        content: result.message,
                        duration: 10000,
                    });
                }

                loading_deleteRef.value = false;
            },
            defaultDifficulty,
        };
    }
}
</script>