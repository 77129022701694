<template>
    <a :href="link" :target="target" @click.stop="">
        <slot></slot>
    </a>
</template>
<script>
export default {
    props: {
        link: '',
        target: ''
    }
}
</script>