<template>
    <n-form ref="formRef" inline :model="formValue" :rules="rules" size="large">
        <n-space vertical> 
            <n-space vertical>
                <n-form-item label="ID задачи на Битрикс 24" path="bitrix">
                    <n-input-number 
                        v-model:value="formValue.bitrix_id"
                        placeholder="Введите id задачи"
                        style="width: 472px; max-width: 100%" 
                    />
                </n-form-item>
                <n-form-item label="Название" path="name">
                    <n-input 
                        v-model:value="formValue.name"
                        placeholder="Введите название задачи"
                        style="width: 472px; max-width: 100%" 
                    />
                </n-form-item>
                <n-form-item label="Дата" path="date">
                    <n-date-picker 
                        v-model:value="formValue.date" 
                        type="date" format="dd MMMM yyyy" 
                        :first-day-of-week="0"
                        placeholder="Укажите дату постановки" 
                        style="width: 472px; max-width: 100%"
                    />
                </n-form-item>
                <n-form-item label="Время на выполнение" path="estimated_time">
                    <n-time-picker 
                        v-model:value="formValue.estimated_time" 
                        default-formatted-value="10:00" 
                        format="HH:mm" 
                        placeholder="Укажите время на выполнение" 
                        style="width: 472px; max-width: 100%" 
                    />
                </n-form-item>
                <n-form-item label="Статус" path="status">
                    <n-select 
                        v-model:value="formValue.status" 
                        :options="options" 
                        style="width: 472px; max-width: 100%" 
                        placeholder="Укажите статус"
                    />
                </n-form-item>
                <n-form-item label="Сложность" v-if="role != 'difficulty'">
                    <n-select 
                        v-model:value="formValue.difficulty" 
                        :options="difficulty_options" 
                        style="width: 472px; max-width: 100%" 
                        placeholder="Укажите сложность"
                    />
                </n-form-item>
            </n-space>
            <n-space>
                <n-button type="primary" :loading="loading" @click="create">
                    Создать
                </n-button>
            </n-space>
        </n-space>
    </n-form>
</template>
<script>
import store from "@/store";
import { defineComponent, ref } from "vue";
import { useNotification } from "naive-ui";
import tasksApi from '@/assets/js/api/tasks.js' 


export default defineComponent({
    data() {
        return {
           
        }
    },
    emits: ['create'],
    setup(props, context) {
        const formRef = ref(null);
        const loadingRef = ref(false);
        const notification = useNotification();
        const role = store.getters.user?.role_api?.value;
        const options = [
            {
                label: 'Выполнено',
                value: 'Выполнено'
            },
            {
                label: 'Не выполнено',
                value: 'Не выполнено'
            }
        ];
        const  difficulty_options = [
            {
                label: 'Легкая',
                value: 1
            },
            {
                label: 'Обычная',
                value: 2
            },
            {
                label: 'Сложная',
                value: 3
            }
        ];

        let formValue = ref({
            name: null,
            date: null,
            estimated_time: null,
            status: null,
            difficulty: null,
        });

        return {
            formRef,
            formValue,
            options,
            difficulty_options,
            role,
            rules: {
                name: {
                    required: true,
                    message: "Обязательное поле",
                },
                date: {
                    required: true,
                    message: "Обязательное поле",
                },
                estimated_time: {
                    required: true,
                    message: "Обязательное поле",
                },
                status: {
                    required: true,
                    message: "Обязательное поле",
                },
            },
            loading: loadingRef,
            async create(e) {
                e.preventDefault();

                loadingRef.value = true;

                await formRef.value?.validate(async (errors) => {
                    if (!errors) {
                        const result = await tasksApi.create(formValue.value);

                        if (result.success) {
                            notification.success({
                                content: "Задача создана.",
                                duration: 10000,
                            });
                            context.emit('create');
                        } else {
                            notification.error({
                                content: result.message,
                                duration: 10000,
                            });
                        }
                    } else {
                        notification.error({
                            content: "Неверные данные формы",
                            duration: 10000,
                        });
                    }

                    loadingRef.value = false;
                });
            }
        };
    }
});
</script>