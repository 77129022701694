<template>
    <div class="auth">
        <n-form ref="formRef" inline :label-width="80" :model="formValue" :rules="rules" size="large" class="auth-form">
            <img src="@/assets/img/logo.svg" class="auth-logo" alt="">
            <n-form-item label="Логин" path="user.username">
                <n-input v-model:value="formValue.user.username" placeholder="Введите логин"/>
            </n-form-item>
            <n-form-item label="Пароль" path="user.password">
                <n-input v-model:value="formValue.user.password" placeholder="Введите пароль" type="password"/>
            </n-form-item>
            <n-form-item>
                <n-button @click="handleValidateClick" type="primary" :loading="loading">
                    Войти
                </n-button>
            </n-form-item>
        </n-form>
    </div>
</template>
  
<script>
import { defineComponent, ref } from "vue";
import { useNotification } from "naive-ui";
import userApi from "@/assets/js/api/user.js";
import router from "@/router";
import { removeTags } from '@/assets/js/helper.js'

export default defineComponent({
    setup() {
        const formRef = ref(null);
        const loadingRef = ref(false)
        const notification = useNotification();

        let formValue = ref({
            user: {
                username: "",
                password: ""
            },
        });

        return {
            formRef,
            formValue, 
            rules: {
                user: {
                    username: {
                        required: true,
                        message: "Обязательное поле",
                        trigger: "blur"
                    },
                    password: {
                        required: true,
                        message: "Пароль должен быть больше 6 символов",
                        trigger: ["input", "blur"],
                        min: 6,
                    }
                },
            },
            loading: loadingRef,
            async handleValidateClick(e) {
                e.preventDefault();
                await formRef.value?.validate(async (errors) => {
                    loadingRef.value = true;

                    if (!errors) {
                        const result = await userApi.login(formValue.value.user);

                        if (result?.token) {
                            notification.success({
                                content: 'Вы вошли как ' + result.user_display_name,
                                duration: 10000,
                            });

                            router.push({ path: '/' });
                        } else {
                            notification.error({
                                content: removeTags(result.message),
                                duration: 10000,
                            });
                        }
                    } else {
                        notification.error({
                             content: "Неверные данные формы", 
                             duration: 10000, 
                        });
                    }

                    loadingRef.value = false;
                });
            }
        };
    }
});
</script>
<style>
    .auth {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px 20px;
    }

    .auth-form {
        width: 100%;
        max-width: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .auth-logo {
        margin: 0 auto 30px;
        width: 100%;
    }

    .auth-form.n-form .n-form-item,
    .auth-form.n-form .n-button {
        width: 100%;
    }

    
</style>