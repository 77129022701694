<template>
    <div class="task-card" @click="log">
        <div class="task-board">
            {{ board }}
        </div>
        <div class="task-labels" v-if="data.labels.length">
            <n-tooltip trigger="hover" v-for="label in data.labels">
                <template #trigger>
                    <div class="task-label" 
                        :style="{ backgroundColor: label.color }"
                        :key="label.id"
                    ></div>
                </template>
                {{ label.name }}
            </n-tooltip>
            
        </div>
        <div class="task-name">
            {{ data.name }}
        </div>

        <hr style="opacity: 0.1;">

        <div class="task-deadline" v-if="deadline">
            Дата сдачи: <br>
            <span :style="{color: deadline.color}">
                {{ deadline.date }}
            </span>
        </div>
        <div class="task-last-activity" v-if="last_activity">
            Последнее обновление: <br>
            {{ last_activity }}
        </div>
        
        <a :href="data.shortUrl" target="_blank" >Перейти в трелло</a>
    </div>
</template>

<script setup>
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { defineProps, computed, ref } from 'vue';

const relationships = [
    { id: '64539fa7fb79c8a9117b802e', board: 'ТАО' },
    { id: '64245d8b0ae68236fe258ebb', board: 'ТАО' },

    { id: '6634c735a413dc2fedfd2cdc', board: 'Fabreex' },
    { id: '6634c735a413dc2fedfd2cdd', board: 'Fabreex' },
    { id: '6634c735a413dc2fedfd2cde', board: 'Fabreex' },
    { id: '6634c735a413dc2fedfd2cdf', board: 'Fabreex' },
];


const now = ref(new Date());

setInterval(() => {
    now.value = new Date();
}, 1000);

const props = defineProps({
    data: {
        type: Object,
    }
});

const deadline = computed(() => {
    try {
        if (props.data.due) {
            const date = new Date(props.data.due);

            let color = 'white';


            if (date.getTime() - 259200000 < now.value.getTime()) {
                color = 'orange';
            }

            if (date.getTime() < now.value.getTime()) {
                color = 'red';
            }

            return {
                date: format(date.getTime(), 'dd MMMM yyyy HH:mm', {locale: ru}),
                color
            }
        }
        return null;
    } catch (error) {
        return null; 
    }
});

const last_activity = computed(() => {
    try {
        if (props.data.dateLastActivity) {
            const date = new Date(props.data.dateLastActivity);
            return format(date.getTime(), 'dd MMMM yyyy HH:mm', {locale: ru});
        }
        return '';
    } catch (error) {
        return ''; 
    }
});

const board = computed(() => {
    const relationship = relationships.find((rel) => {
        return props.data.idList == rel.id;
    })

    return relationship.board;
});

function log() {
    console.log(props.data);
}
</script>

<style>
.task-card {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.task-board {
    opacity: 0.7;
}

.task-labels {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.task-label {
    border-radius: 4px;
    width: 16px;
    height: 8px;
}


.task-last-activity {
    opacity: 0.7;
}

.task-name {
    font-size: 16px;
    opacity: 0.9;
}

.task-card a {
    width: max-content;
}

.task-card hr {
    width: 100%;
}
</style>