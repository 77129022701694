import { createStore } from 'vuex'
import { pageModule } from './pageModule'
import { appModule } from './appModule'

export default createStore({
    state: () => {
        return {
            user: {},
        }
    },
    getters: {
        user(state) {
            return state.user;
        }
    },
    mutations: {
        user(state, object) {
            state.user = object;
        },
    },
    actions: {

    },
    modules: {
        page: pageModule,
        app: appModule
    }
})
