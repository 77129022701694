<template>
    <n-form ref="formRef" inline :model="formValue" :rules="rules" size="large">
        <n-space vertical> 
            <n-space>
                <n-form-item label="Дата" path="date">
                    <n-date-picker 
                        v-model:value="formValue.date" 
                        type="date" format="dd MMMM yyyy" 
                        :first-day-of-week="0"
                        placeholder="Укажите дату отчета" 
                        style="width: 472px; max-width: 100%"
                    />
                </n-form-item>
            </n-space>
            <n-space justify="space-between" style="gap: 0px 20px;">
                <n-form-item label="Начало рабочего дня" path="start_time">
                    <n-time-picker 
                        v-model:value="formValue.start_time" 
                        default-formatted-value="10:00" 
                        format="HH:mm" 
                        placeholder="Укажите время" 
                        style="width: 218px; max-width: 100%" 
                    />
                </n-form-item>
                <n-form-item label="Конец рабочего дня" path="end_time">
                    <n-time-picker 
                        v-model:value="formValue.end_time" 
                        default-formatted-value="18:00" 
                        format="HH:mm" 
                        placeholder="Укажите время" 
                        style="width: 218px; max-width: 100%" 
                    />
                </n-form-item>
            </n-space>
            <n-form-item label="Комментарий" >
                <n-input v-model:value="formValue.comment" type="textarea" />
            </n-form-item>
            <n-space>
                <n-form-item label="Задачи" >
                    <n-dynamic-input v-model:value="formValue.tasks" :min="1" :on-create="onCreate"  #="{ index, value }">
                        <div style="display: flex; flex-direction: column; justify-content: space-between; width: 100%;">
                            <n-divider title-placement="left" style="font-size: 14px; margin-top: 10px;">
                                Задача №{{ index + 1 }}
                            </n-divider>
                            <n-form-item 
                                ignore-path-change 
                                :path="`tasks[${index}].id`" 
                                :show-label="false"
                                :rule="dynamicInputRule"
                                style="width: 100%" 
                            >
                                <n-select 
                                    v-model:value="formValue.tasks[index].id" 
                                    :options="options" 
                                    style="width: 400px" 
                                    placeholder="Выберите задачу"
                                />
                            </n-form-item>
                            <n-form-item 
                                ignore-path-change
                                :path="`tasks[${index}].time`"
                                :show-label="false"
                                :rule="dynamicInputRule"
                            >
                                <n-time-picker 
                                    v-model:value="formValue.tasks[index].time" 
                                    default-formatted-value="00:00" 
                                    format="HH:mm" 
                                    style="width: 400px" 
                                    placeholder="Укажите время"
                                />
                            </n-form-item>
                        </div>
                    </n-dynamic-input>
                </n-form-item>
            </n-space>
            <n-space>
                <n-button type="primary" :loading="loading" @click="create">
                    Создать
                </n-button>
            </n-space>
        </n-space>
    </n-form>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useNotification } from "naive-ui";
import tasksApi from '@/assets/js/api/tasks.js' 
import reportsApi from '@/assets/js/api/reports.js' 


export default defineComponent({
    data() {
        return {
            options: [],
        }
    },
    async created() {
        const result = await tasksApi.getList();

        if (result?.success) {
            this.options = result.tasks;
        }
    },
    emits: ['create'],
    setup(props, context) {
        const formRef = ref(null);
        const loadingRef = ref(false);
        const notification = useNotification();

        let formValue = ref({
            date: null,
            start_time: null,
            end_time: null,
            comment: '',
            tasks: [{
                id:   null,
                time: null
            }]
        });

        return {
            formRef,
            formValue,
            onCreate() {
                return {
                    id:   null,
                    time: null
                };
            },
            rules: {
                date: {
                    required: true,
                    message: "Обязательное поле",
                },
                start_time: {
                    required: true,
                    message: "Обязательное поле",
                },
                end_time: {
                    required: true,
                    validator(rule, value) {
                        if (!value) {
                            return new Error('Обязательное поле')
                        }
                        else if (formValue.value.start_time >= value) {
                            return new Error('Неверная дата')
                        }
                        return true;
                    }
                },
            },
            dynamicInputRule: {
                required: true,
                message: "Обязательное поле",
            },
            loading: loadingRef,
            async create(e) {
                e.preventDefault();

                loadingRef.value = true;

                await formRef.value?.validate(async (errors) => {
                    if (!errors) {
                        const result = await reportsApi.create(formValue.value);

                        if (result.success) {
                            notification.success({
                                content: "Отчет создан.",
                                duration: 10000,
                            });
                            context.emit('create');
                        } else {
                            notification.error({
                                content: result.message,
                                duration: 10000,
                            });
                        }
                    } else {
                        notification.error({
                            content: "Неверные данные формы",
                            duration: 10000,
                        });
                    }

                    loadingRef.value = false;
                });
            }
        };
    }
});
</script>