import axios from 'axios'

export const pageModule = {
    state: () => {
        return {
            loading: true,
            page_data: {}, 
        }
    },
    getters: {

    },
    mutations: {
        setLoading: (state, bool) => {
            state.loading = bool;
        },
        setPageData: (state, page) => {
            state.page_data = page;
        }
    },
    actions: {
        getPageData: async ({commit}, page_name) => {
            try {
                commit('setLoading', true);
                const response = await axios.get('https://your-startup.space/v1/methods/' + page_name);
                if (response.status == 200) {
                    commit('setPageData', response.data);
                }
            } catch (error) {
                if (error.response.status == 404) {
                    document.location = '/404'
                }
                console.log(error.message);
            }
            finally {
                commit('setLoading', false);
            }
        }
    },
    namespaced: true
}