<template>
    <div class="report-card" v-if="is_loaded">
        <div class="report-card-header">
            <div class="report-card-title">
                <n-h2 style="margin-bottom: 0;">Отчет от {{ report?.date }}</n-h2>
                <n-tag :bordered="false" :type="report?.verified_direct_supervisor ? 'success' : 'error'">
                    {{ report?.verified_direct_supervisor ? 'Проверен' : 'Не проверен' }}
                </n-tag>
                <n-tag :bordered="false" :type="report?.verified_supervisor ? 'success' : 'error'">
                    {{ report?.verified_supervisor ? 'Утвержден' : 'Не утвержден' }}
                </n-tag>
            </div>
            <n-space vertical align="end">
                <n-button @click="$emit('edit')">Редактировать</n-button>
                <n-space justify="end" v-if="can_check || can_approve">
                    <n-button v-if="can_check   && !report?.verified_direct_supervisor" @click="checkReport('verified_direct_supervisor', true)"  type="success" secondary>Проверить</n-button>
                    <n-button v-if="can_check   && report?.verified_direct_supervisor"  @click="checkReport('verified_direct_supervisor', false)" type="error"   secondary>Отменить проверку</n-button>
                    <n-button v-if="can_approve && !report?.verified_supervisor"        @click="checkReport('verified_supervisor',        true)"  type="success" secondary>Утвердить</n-button>
                    <n-button v-if="can_approve && report?.verified_supervisor"         @click="checkReport('verified_supervisor',        false)" type="error"   secondary>Отменить утверждение</n-button>
                </n-space>
            </n-space>
        </div>
        <div class="report-work-time">
            <div class="report-work-time-item">
                <div class="report-work-time-label">
                    Автор
                </div>
                <div class="report-work-time-value">
                    {{ report?.author }}
                </div>
            </div>
            <div class="report-work-time-item">
                <div class="report-work-time-label">
                    Время начала работы
                </div>
                <div class="report-work-time-value">
                    {{ report?.start_time }}
                </div>
            </div>
            <div class="report-work-time-item">
                <div class="report-work-time-label">
                    Время окончания работы
                </div>
                <div class="report-work-time-value">
                    {{ report?.end_time }}
                </div>
            </div>
            <div class="report-work-time-item">
                <div class="report-work-time-label">
                    Оценка
                </div>
                <n-tag :bordered="false" :type="kpi_type" style="margin-top: 5px;">
                    {{ kpi_text }}
                </n-tag>
            </div>
        </div>
        <template v-if="report?.tasks?.length > 0">
            <n-divider />
            <n-h3>Задачи</n-h3>
            <n-data-table :columns="columns" :data="report?.tasks" />
        </template>
        <template v-if="report?.comment">
            <n-divider />
            <div class="report-comment-label">
                Комментарий
            </div>
            <div class="comment" v-html="report?.comment">
            </div>
        </template>
    </div>
    <n-space justify="center" align="center" v-else style="height: 100%; min-width: 300px; min-height: 200px;">
        <n-spin size="large" />
    </n-space>
</template>
<script>
import { h } from 'vue'
import { NTag } from 'naive-ui'
import LinkVue from '@/components/base/Link.vue'
import reportsApi from '@/assets/js/api/reports.js'
import { useNotification } from "naive-ui"

export default {
    props: {
        data: {},
        id: null,
    },
    data() {
        return {
            report:      {},
            is_loaded:   false,
            can_check:   false,
            can_approve: false,
            kpi_type:    '',
            kpi_text:    '',
        }
    },
    computed: {
        columns() {
            return [
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: 'Битрикс 24',
                    key: 'bitrix_id',
                    align: 'center',
                    render(row) {
                        if ( row.bitrix_id) {
                            return h(
                                LinkVue,
                                {
                                    link:  'https://yourstartup.bitrix24.ru/company/personal/user/0/tasks/task/view/' + row.bitrix_id + '/',
                                    target: '_blank'
                                },
                                {
                                    default: () => 'Задача №' + row.bitrix_id
                                }
                            );
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    title: 'Название',
                    key: 'name'
                },
                {
                    title: 'Дата постановки',
                    key: 'date',
                    align: 'center',
                },
                {
                    title: 'Время (оценка)',
                    key: 'estimated_time',
                    align: 'center',
                },
                {
                    title: 'Время (потраченное)',
                    key: 'history',
                    align: 'center',
                },
                {
                    title: 'Исполнители',
                    key: 'executor',
                    align: 'center',
                    render(row) {
                        const tags = row.executor.map((tagKey) => {
                            return h(
                                NTag,
                                {
                                    style: {
                                        marginRight: '6px',
                                        marginBottom: '6px'
                                    },
                                },
                                {
                                    default: () => tagKey
                                }
                            )
                        })
                        return tags
                    }
                },
                {
                    title: 'Сложность',
                    key: 'difficulty',
                    align: 'center',
                    render(row) {
                        let type  = '',
                            label = '';

                        switch (row.difficulty) {
                            case 'Легкая':
                                type  = 'success';
                                label = 'Легкая';
                                break;

                            case 'Сложная':
                                type = 'info';
                                label = 'Сложная';
                                break;

                            default:
                                type = 'default';
                                label = 'Обычная';
                                break;
                        }

                        return h(NTag,
                            {
                                type: type,
                                bordered: false
                            },
                            {
                                default: () => label
                            }
                        )
                    }
                },
                {
                    title: 'Статус',
                    key: 'status',
                    align: 'center',
                    render(row) {
                        let type = '';

                        switch (row.status) {
                            case 'Выполнено':
                                type = 'success';
                                break;

                            case 'Не выполнено':
                                type = 'error';
                                break;

                            default:
                                type = 'info';
                                break;
                        }

                        return h(NTag,
                            {
                                type: type,
                                bordered: false,
                                style: 'min-width: 115px; justify-content: center;'
                            },
                            {
                                default: () => row.status
                            }
                        )
                    }
                },
                {
                    title: 'Оценка',
                    key: 'kpi',
                    align: 'center',
                    render(row) {
                        let type = '',
                            text = row.kpi;

                        switch (row.kpi) {
                            case 'Нормально':
                                type = 'info';
                                break;
                        
                            case 'Хорошо':
                                type = 'success';
                                break;

                            case 'Плохо':
                                type = 'error';
                                break;
                        }

                        return h(NTag,
                            {
                                type: type,
                                bordered: false,
                                style: 'min-width: 90px; justify-content: center;'
                            },
                            {
                                default: () => text
                            }
                        )
                    }
                },
            ]
        },
    },
    emits: ['edit'],
    async created() {
        if (this.id) {
            this.is_loaded = false;

            const result = await reportsApi.getById(this.id);

            if (result.success) {
                this.report   = result.report;
                this.kpi_text = this.report.kpi;

                switch (this.report.kpi) {
                    case 'Нормально':
                        this.kpi_type = 'info';
                        break;
                
                    case 'Хорошо':
                        this.kpi_type = 'success';
                        break;

                    case 'Плохо':
                        this.kpi_type = 'error';
                        break;
                }
            }

            this.is_loaded = true;

            if (this.$store.state.user?.role_api?.value == 'boss') {
                this.can_approve = true;
                this.can_check   = true;
            } else if (this.$store.state.user?.role_api?.value == 'director' || this.$store.state.user?.role_api?.value == 'senior_employee') {
                this.can_check   = true;
            }

        } else {
            this.report = this.data;
            this.is_loaded = true;
        }
    },
    setup(props) {
        const notification = useNotification();

        return {
            async checkReport(type, value) {
                const result = await reportsApi.check(props.id, value, type);

                if (!result) {
                    notification.error({
                        content: 'Не удалось подписать.',
                        duration: 10000,
                    });

                    return;
                }

                if (result.success) {
                    notification.success({
                        content: 'Успешно подписано.',
                        duration: 10000,
                    });
                } else {
                    notification.error({
                        content: result.message,
                        duration: 10000,
                    });
                }
            }
        }
    }
}
</script>
<style>
.report-card {
    padding: 20px;
    border: 1px #2d2d30 solid;
}

.report-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.report-card-title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 100px;
}

.report-work-time {
    display: flex;
    align-items: flex-start;
    gap: 40px;
}

.report-work-time-label,
.report-comment-label {
    line-height: 1.25;
    font-size: 14px;
    opacity: 0.6;
}

.report-work-time-value {
    line-height: 2;
    font-size: 18px;
}

.comment {
    margin-top: 10px;
}
</style>