export function randomInteger(min, max) {
    const rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
}

export function removeTags(str) { 
    if ((str===null) || (str==='')) 
        return false; 
    else
        str = str.toString(); 
          
    return str.replace( /(<([^>]+)>)/ig, ''); 
} 

export function getFormatedDate(str) {
    if (str) {
        const dictionary = {
            'января':   '01',
            'февраля':  '02',
            'марта':    '03',
            'апреля':   '04',
            'мая':      '05',
            'июня':     '06',
            'июля':     '07',
            'августа':  '08',
            'сентября': '09',
            'октября':  '10',
            'ноября':   '11',
            'декабря':  '12',
        }

        for (const key in dictionary) {
            const temp = str.replace(key, dictionary[key]);
            if (temp != str) {
                str = temp;
                break;
            }
        }

        const words = str.split(' ');

        str = words[2] + '/' + words[1] + '/' + words[0];

        return str;
    }

    return null;
}


export function getFormatedTime(str) {
    if (str) {
        const now = new Date();
        return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2,0)}-${now.getDate()} ${str.replace(' ч. ', ':').replace(' мин.', ':00')}`;
    }

    return null;
}

export function numberWithSpaces(x) { 
    if (x) { 
        const parts = x.toString().split("."); 
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " "); 
        return parts.join("."); 
    } else { 
        return ""; 
    } 
}