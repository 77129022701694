import Api from "@/assets/js/api/index.js";

class ReportsApi extends Api {
    async get(page = 1, user_id = null) {
        try {
            const params = user_id ? new URLSearchParams({page, user_id}).toString() : new URLSearchParams({page}).toString();
            const result = await this.send('methods/reports/?' + params);

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getById(id) {
        try {
            if (id) {
                const result = await this.send('methods/report/?' + new URLSearchParams({id}).toString());
    
                return result?.data;
            }

            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getToday() {
        try {
            const result = await this.send('methods/report/today');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getTodayForUsers() {
        try {
            const result = await this.send('methods/reports/workers');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async create(fields, user_id = false) {
        try {
            if (!fields) {
                return false;
            }

            const data = {
                fields: {
                    date:       new Date(fields.date).toGMTString(),
                    start_time: new Date(fields.start_time).toGMTString(),
                    end_time:   new Date(fields.end_time).toGMTString(),
                    comment:    fields.comment,
                }
            }

            if (fields.tasks && fields.tasks.length > 0) {
                data.tasks = fields.tasks.map((task) => {
                    return {
                        id: task.id,
                        time: new Date(task.time).toGMTString(),
                    }
                });
            }

            if (user_id) {
                data.for_user = user_id;
            }

            const result = await this.send('methods/report/create', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async update(fields, id, user_id = false) {
        try {
            if (!fields || !id) {
                return false;
            }

            const data = {
                id,
                fields: {
                    date:       new Date(fields.date).toGMTString(),
                    start_time: new Date(fields.start_time).toGMTString(),
                    end_time:   new Date(fields.end_time).toGMTString(),
                    comment:    fields.comment,
                }
            }

            if (fields.tasks && fields.tasks.length > 0) {
                data.tasks = fields.tasks.map((task) => {
                    return {
                        id: task.id,
                        time: new Date(task.time).toGMTString(),
                    }
                });
            }

            if (fields?.verified_direct_supervisor) {
                data.fields.verified_direct_supervisor = fields.verified_direct_supervisor;
            }

            if (fields?.verified_supervisor) {
                data.fields.verified_supervisor = fields.verified_supervisor;
            }

            if (user_id) {
                data.for_user = user_id;
            }

            const result = await this.send('methods/report/update', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async delete(id) {
        try {
            if (!id) {
                return false;
            }

            const data = {
                id
            }

            const result = await this.send('methods/report/delete', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }


    async check(id, value, type) {
        try {
            if (!id || !value || !type) {
                return false;
            }

            const data = {
                id,
                value: Number(value),
                type
            }

            const result = await this.send('methods/report/check', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async mouthlyReport() {
        try {
            const result = await this.send('methods/mouthly-report');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getUserList() {
        try {
            const result = await this.send('methods/usernames');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new ReportsApi();