import axios from "axios";
import router from "@/router";
axios.defaults.withCredentials = true;

class Api {
	constructor(params) {
		this.default_params = {
			url: 'https://workspace.your-startup.space/v1/',
		}

		this.params = Object.assign(this.default_params, params);
	}

	async send(path, data = null, method = 'get') {
        try {
            const axios_params = {
                method,
                url: this.params.url + path,
                data,
            }

            const token = localStorage.getItem('token');

            if (token) {
                axios_params.headers = {
                    Authorization: 'Bearer ' + token
                }
            }

            let result = await axios(axios_params);

            if (result.status != 200) {
                console.log(result);
                return;
            }

            return result;
        } catch (error) {
            if (error.response.status) {
                switch (error.response.status) {
                    case 403:
                        localStorage.removeItem('token');
                        sessionStorage.removeItem('user');
                        router.push('/login');
                        break;
                
                    default:
                        break;
                }
            }
        }
    }
}

export default Api;