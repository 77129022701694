<template>
    <div class="reports">
        <div class="reports-header">
            <n-h2>Текущие отчеты сотрудников</n-h2>
        </div>
        <template v-if="is_loaded">
            <n-data-table :columns="columns" :data="reports" :row-props="rowProps"/>
        </template>
        <n-space justify="center" align="center" v-else style="height: 100%; min-height: 680px;">
            <n-spin size="large" />
        </n-space>
    </div>
</template>
    
<script>
import reportsApi from '@/assets/js/api/reports.js'
import { h } from 'vue'
import { NTag } from 'naive-ui'

export default {
    name: 'Today reports list for users',
    data() {
        return {
            reports: [],
            is_loaded: false,
        }
    },
    methods: {
        async getReports() {
            this.is_loaded = false;

            const result = await reportsApi.getTodayForUsers();

            if (result.success) {
                this.reports      = result.reports;
            }

            this.is_loaded = true;
        },
        update() {
            this.getTodayForUsers();
        },
    },
    emits: ['info-report'],
    computed: {
        columns() {
            return [
                {
                    title: 'ID cотрудникa',
                    key: 'id',
                },
                {
                    title: 'Сотрудник',
                    key: 'name',
                    render(row) {
                        return h(
                            NTag,
                            {},
                            {
                                default: () => row.name
                            }
                        )
                    }
                },
                {
                    title: 'Роль',
                    key: 'role',
                },
                {
                    title: 'Отчет',
                    key: 'report_id',
                    align: 'center',
                    render(row) {
                        let type = 'error',
                            text = 'Не подготовлен';

                        if (row.report_id) {
                            type = 'success';
                            text = 'Отчет №' + row.report_id;
                        }

                        return h(
                            NTag,
                            { 
                                type: type,
                                bordered: false,
                                style: 'min-width: 132px; justify-content: center;'
                            },
                            {
                                default: () => text
                            }
                        )
                    } 
                },
                {
                    title: 'Время создания',
                    key: 'date',
                    align: 'center'
                },
                {
                    title: 'Время изменения',
                    key: 'date_modified',
                    align: 'center'
                },
                
            ]
        }
    },
    async created() {
        this.getReports();
    },
    setup(props, context) {
        return {
            rowProps: (row) => {
                if (row.report_id) {
                    return {
                        style: 'cursor: pointer;',
                        onClick: () => {
                            context.emit('info-report', row.report_id);
                        }
                    }
                }
            },
        }
    },
}
</script>