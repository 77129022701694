<template>
    <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides" :locale="ruRU" :date-locale="dateRuRU">
        <n-loading-bar-provider>
            <n-message-provider>
                <n-notification-provider  placement="bottom-right">
                    <n-dialog-provider>
                        <router-view />
                    </n-dialog-provider>
                </n-notification-provider>
            </n-message-provider>
        </n-loading-bar-provider>
    </n-config-provider>
</template>

<script>
import 'vfonts/FiraCode.css'
import { defineComponent } from 'vue'
import { darkTheme, ruRU, dateRuRU } from 'naive-ui'

export default defineComponent({
    setup() {
        return {
            ruRU,
            dateRuRU,
            darkTheme,
            themeOverrides: {
                common: {
                    primaryColor: '#5457F5',
                    primaryColorHover: '#6a6cf3',
                    primaryColorPressed: '#4144f3',
                    baseColor: "#FFFFFF"
                },
            }
        }
    }
})
</script>

<style>
@font-face {
    font-family: "NotoMono";
    src: local("NotoMono"),
        url("./assets/fonts/NotoMono/NotoMono-Regular.ttf");
    font-display: swap;
}

* {
    box-sizing: border-box;
    font-family: 'NotoMono';
}

body {
    margin: 0;
    background-color: #101014;
    color: #FFF;
}

.container {
    max-width: 1520px;
    padding: 0 20px;
    margin: 0 auto;
}

.page {
    padding: 20px 0;
}

a {
    color: #6a6cf3;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
}

a:hover {
    color: #5457F5;
    border-bottom: 1px solid #5457F5;
}

.n-drawer-mask,
.n-modal-mask {
    backdrop-filter: blur(5px);
}

.widget-list {
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin: 100px 0; 
}
</style>