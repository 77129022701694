<template>
    <div class="reports">
        <div class="reports-header" style="margin-bottom: 20px;">
            <div class="reports-header-group">
                <n-h2 style="margin-bottom: 0;">Отчеты</n-h2>
                <n-select v-if="user_list.length > 0" 
                    v-model:value="user_id" 
                    :options="user_list" 
                    @update:value="update"
                    placeholder="Выбирете сотрудника" 
                    style="min-width: 300px;"
                />
            </div>
            <n-button @click="createNew">Создать новый отчет</n-button>
        </div>
        <template v-if="is_loaded">
            <n-data-table :columns="columns" :data="reports" :row-props="rowProps"/>
            <n-pagination v-model:page="page" :page-count="page_count" style="margin-top:20px"/>
        </template>
        <n-space justify="center" align="center" v-else style="height: 100%; min-height: 680px;">
            <n-spin size="large" />
        </n-space>
    </div>
</template>
    
<script>
import reportsApi from '@/assets/js/api/reports.js'
import { h } from 'vue'
import { NTag } from 'naive-ui'

export default {
    name: 'Reports List',
    data() {
        return {
            page: 1,
            page_count: 0,
            reports: [],
            is_loaded: false,
            user_id: null,
            user_list: [],
        }
    },
    methods: {
        async getReports() {
            this.is_loaded = false;

            const result = await reportsApi.get(this.page, this.user_id);

            if (result.success) {
                this.reports    = result.reports;
                this.page_count = result.all_page;
            }

            this.is_loaded = true;
        },
        createNew() {
            this.$emit('new-report');
        },
        update() {
            if (this.page == 1) {
                this.getReports();
            } else {
                this.page = 1;
            }
        },
        async getUserList() {
            const result = await reportsApi.getUserList();

            if (result.success) {
                this.user_list = result.users;
            }
        }
    },
    emits: ['new-report', 'info-report'],
    watch: {
        page(new_value) {
            this.getReports()
        }
    },
    computed: {
        columns() {
            return [
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: 'Исполнитель',
                    key: 'author'
                },
                {
                    title: 'Дата',
                    key: 'date',
                    align: 'center',
                },
                {
                    title: 'Начало рабочего дня',
                    key: 'start_time',
                    align: 'center',
                },
                {
                    title: 'Конец рабочего дня',
                    key: 'end_time',
                    align: 'center',
                },
                // {
                //     title: 'Оценка',
                //     key: 'kpi',
                //     align: 'center',
                //     render(row) {
                //         let type = '',
                //             text = ' - ';

                //         if (row.kpi == 0) {
                //             type = 'info';
                //             text = 'Нормально';
                //         } else if (row.kpi > 0) {
                //             type = 'success';
                //             text = 'Хорошо';
                //         } else {
                //             type = 'error';
                //             text = 'Плохо';
                //         }

                //         return h(NTag,
                //             {
                //                 type: type,
                //                 bordered: false,
                //                 style: 'width: 100%; justify-content: center;'
                //             },
                //             {
                //                 default: () => text
                //             }
                //         )
                //     }
                // },
                {
                    title: 'Проверен',
                    key: 'verified_direct_supervisor',
                    align: 'center',
                    render (row) {
                        const type = row.verified_direct_supervisor ? 'success' : 'error',
                              text = row.verified_direct_supervisor ? 'Проверен' : 'Не проверен';

                        return h( NTag,
                            {
                                type: type,
                                bordered: false,
                                style: 'min-width: 110px; justify-content: center;'
                            },
                            {
                                default: () => text
                            }
                        )
                    }
                },
                {
                    title: 'Утвержден',
                    key: 'verified_supervisor',
                    align: 'center',
                    render (row) {
                        const type = row.verified_supervisor ? 'success' : 'error',
                              text = row.verified_supervisor ? 'Утвержден' : 'Не утвержден';

                        return h( NTag,
                            {
                                type: type,
                                bordered: false,
                                style: 'min-width: 115px; justify-content: center;'
                            },
                            {
                                default: () => text
                            }
                        )
                    }
                },
            ]
        }
    },
    async created() {
        this.getUserList();
        this.getReports();
    },
    setup(props, context) {
        return {
            rowProps: (row) => {
                return {
                    style: 'cursor: pointer;',
                    onClick: () => {
                        context.emit('info-report', row.id);
                    }
                }
            },
        }
    }
}
</script>

<style>
    .reports-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .reports-header-group {
        display: flex;
        align-items: center;
        gap: 20px;
    }
</style>