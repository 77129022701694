<template>
    <Preloader />
    <header>
        <div class="container">
            <n-space justify="space-between" align="center">
                <n-space align="center" class="w-100">
                    <n-h1 style="margin-bottom: 0;">{{ store.state?.user?.data?.display_name }}</n-h1>
                    <n-tag size="small" style="opacity: 0.6;">{{ store.state?.user?.role_api?.label }}</n-tag>
                </n-space>

                <n-space align="center" vertical>
                    <div class="date">
                        {{ date }}
                    </div>
                    <div class="time">
                        {{ time }}
                    </div>
                </n-space>
                
                <n-space>
                    <n-button @click="logout">
                        Выход
                    </n-button>
                </n-space>
            </n-space>
        </div>
    </header>
    <main style="margin-top: 130px;">
        <div class="container">
            <n-space align="center" style="margin-bottom: 40px;" :size="50">
                <n-h2 style="margin: 0;">
                    Список задач (поддержка)
                </n-h2>
                <n-button @click="getTasks" :loading="loading">
                    <template v-if="loading">
                        Обновление...
                    </template>
                    <template v-else>
                        Обновить
                    </template>
                </n-button>
            </n-space>
            <n-scrollbar x-scrollable>
                <div class="tasks-columns">
                    <column v-for="items, key in tasks"
                        :type="key"
                        :tasks="items"
                        :key="key"
                    />
                </div>
            </n-scrollbar>
        </div>
    </main>
</template>

<script setup>
import Preloader from '@/components/base/Preloader.vue'
import Column from '@/components/support-tasks/Column.vue'

import moment from 'moment';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

import userApi  from '@/assets/js/api/user';
import tasksApi from '@/assets/js/api/support-tasks';

const store = useStore();
const router = useRouter();

store.commit('page/setLoading', true);

moment.locale('ru');

const date = ref();
const time = ref();

onMounted(() => {
    time.value = moment().format('LTS'); 
    date.value = moment().format('dd, DD MMMM');

    setInterval(() => {
        time.value = moment().format('LTS'); 
        date.value = moment().format('dd, DD MMMM');
    }, 1000);      
});

async function getUser() {
    const result = await userApi.me();

    if (result.susses) {
        store.commit('user', result.user);
    }

    store.commit('page/setLoading', false);
}

function logout() {
    router.push({ path: 'login' });
}

const tasks = ref({});
const loading = ref(false);

let id_timeout = null;

async function getTasks() {
    loading.value = true;
    clearTimeout(id_timeout);

    const res = await tasksApi.getList();
    
    if (res.success) {
        tasks.value = res.tasks;
    }

    loading.value = false;
    id_timeout = setTimeout(() => {
        getTasks();
    }, 60000);
}

async function init() {
   await getUser();
   await getTasks();
}

init();
</script>

<style>
    .tasks-columns {
        display: flex;
        gap: 40px;
        width: max-content;
    }
</style>