import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import NotFound from '@/views/404View.vue'
import Auth from '@/views/Auth.vue'
import TaskListView from '@/views/TaskListView.vue'

const routes = [
    {
        path: '/login',
        name: 'Auth',
        component: Auth
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/404',
        component: NotFound
    },
    {
        path: '/tasks',
        component: TaskListView
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

export default router
