import router from "@/router";
import Api from "@/assets/js/api/index.js";

class UserApi extends Api {
    async login(data) {
        try {
            const result = await this.send('jwt-auth/v1/token', data, 'post');
            
            if (result?.data.token) {
                localStorage.setItem('token', result?.data.token);
                return result?.data;
            }

            return result ?? {message: 'Неверный пароль или логин.'};
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    async logout() {
        localStorage.setItem('token', '');
        sessionStorage.setItem('user', '');
        router.push('/login');
    }

    async me(cash = true) {
        try {
            if (cash) {
                const user = sessionStorage.getItem('user');
                if (user) {
                    return {
                        susses: true,
                        user: JSON.parse(user),
                    }
                }
            }

            const result = await this.send('methods/me');

            if (result?.data.susses) {
                sessionStorage.setItem('user', JSON.stringify(result.data.user));
            }
           
            return result?.data;
        } catch (error) {
            console.error(error);
            sessionStorage.setItem('user', '');
            return false;
        }
    }
}

export default new UserApi();